<template>
    <v-container>
        <v-snackbar id="snackbar" v-model="snackbar" :color="color" top :timeout="5000">
            <v-row>
                {{ text }}
                <v-spacer></v-spacer>
                <v-btn
                dark
                icon
                @click="snackbar = false"
                >
                <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-snackbar>
        <v-form v-model="validForm" ref="waiverForm">
            <h1 class="blueDark--text mb-5">New Waiver</h1>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select :rules="[v => v.length > 0 || 'Item is required']" :items="airlines" label="Airlines" placeholder="Search Airlines" v-model="waiver.airline_codes" item-text="name" item-value="code" multiple outlined color="blue">
                        <template v-slot:selection="{ item, i }">
                            <v-chip close close-icon="mdi-close-circle" @click:close="waiver.airline_codes.splice(i, 1)">
                                <span>{{ item.name.split(' ')[0] }} ({{ item.code }}) </span>
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-file-input
                    :rules="[v => !!v || 'Item is required']"
                    label="Add File"
                    outlined
                    height="56"
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip-plus"
                    accept="image/*, .pdf, .xlsx, .docx"
                    v-model="waiver.file"
                    ></v-file-input>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-hover v-slot="{ hover }">
                        <v-btn :loading="saveBtnLoad" tile class="px-15 py-5 my-5" :color="hover ? 'blueDark' : 'orange'" dark @click="saveWaiver">save</v-btn>
                    </v-hover>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { contractAirlines, storeWaiver, headers } from '../../../links'

export default {
  data () {
    return {
      regions: [],
      airlines: [],
      waiver: {
        airline_codes: [],
        file: null
      },
      snackbar: false,
      color: '',
      text: '',
      validForm: true,
      saveBtnLoad: false,
      loadingContract: false
    }
  },
  methods: {
    saveWaiver () {
      this.$refs.waiverForm.validate()
      if (this.validForm && this.waiver.airline_codes.length) {
        const formData = new FormData()
        this.waiver.airline_codes.forEach((el, i) => {
          formData.append(`airline_codes[${i}]`, el)
        })
        formData.append('name', this.waiver.file, this.waiver.file.name)
        this.saveBtnLoad = true
        this.$http.post(storeWaiver, formData, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
          this.snackbar = true
          this.color = 'success'
          this.text = 'Waiver is added successfully'
          this.$refs.waiverForm.reset()
          this.contract.details = 0
        }).catch(err => {
          this.snackbar = true
          this.color = err.data.status === 200 ? 'success' : 'error'
          this.text = err.data.message
          if (err.data.status === 200) {
            this.$refs.waiverForm.reset()
            this.contract.details = 0
          }
        }).finally(() => {
          this.saveBtnLoad = false
        })
      } else {
        this.snackbar = true
        this.color = 'warning'
        this.text = 'Please fill all fields'
      }
    }
  },
  created () {
    this.$http.get(contractAirlines, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      this.airlines = response.body.data
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>
